<!--
 * @Author: CL
 * @Date: 2021-06-25 22:55:55
 * @LastEditTime: 2021-07-22 12:52:24
 * @Description: 每日一句的后台管理
-->

<template>
  <div class="everydayback-contain cl-wrap">
    <div class="cl-table-main" v-loading="loading">
      <div class="main-top">
        <div>每日一句信息</div>
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="openAddDayModel">添加</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ textAlign: 'center' }"
        class="table-wrap"
        max-height="580"
      >
        <el-table-column
          prop="content"
          label="每日一句"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="handleEdit(scope.row)">修改</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <Pagination 
          :page="page" 
          :size="size" 
          :total="total" 
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>

    <Dialog 
      :isShow="addDayModel" 
      title="添加每日一句" 
    >
      <addDay @closeAddDayModel="closeAddDayModel" />
    </Dialog>

    <Dialog 
      :isShow="editDayModel" 
      title="修改每日一句" 
    >
      <editDay
        :editInfo="editInfo"
        :key="key" 
        @closeEditDayModel="closeEditDayModel" 
      />
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog';
import Pagination from '@/components/Pagination';
import { queryByPage, delEveryDay } from '@/api/everyday.js';
import addDay from './components/addDayModel.vue';
import editDay from './components/editDayModel.vue';

export default {
  data(){
    return {
      page: 1,
      size: 10,
      total: 0,
      tableData: [],
      loading: true,

      addDayModel: false,
      editDayModel: false,
      editInfo: {},
      key: ''
    }
  },

  methods: {
    /**
     * 修改
     */
    handleEdit(data){
      this.editInfo = data;
      this.editDayModel = true;
    },

    /**
     * 关闭修改模态框
     */
    closeEditDayModel(flag, isRefresh){
      this.editDayModel = flag;
      this.key - new Date().getTime();
      if(isRefresh == 'refresh'){
        this.queryEveryDay();
      }
    },

    /**
     * 删除
     */
    handleDelete(data){
      const { id } = data;
      this.$utils.confirm('您确定要删除此每日一句吗?').then(async () => {
        const res = await delEveryDay(id);
        if(res.code == 200){
          this.$showMessage({
            type: 'success',
            message: '删除成功!'
          });
          this.queryEveryDay();
          this.$store.dispatch('everyDay/queryWords');
        }
      }).catch(err => {
        //点击了取消
        this.$showMessage({
          type: 'info',
          message: '已取消删除'
        }); 
      })
    },

    /**
     * size发生改变
     */
    handleSizeChange(val){
      this.size = val;
    },

    /**
     * page发生改变
     */
    handleCurrentChange(val){
      this.page = val;
    },

    /**
     * 分页查询数据
     */
    async queryEveryDay(){
      this.loading = true;
      try{
        const { page, size } = this;
        const res = await queryByPage(page, size);
        this.loading = false;
        if(res.code == 200){
          const { count, rows } = res.data;
          this.total = count;
          this.tableData = rows;
        }
      }catch(err){
        console.log(err);
      }
    },

    /**
     * 打开添加每日一句的模态框
     */
    openAddDayModel(){
      console.log('a');
      this.addDayModel = true;
    },

    /**
     * 关闭添加模态框
     */
    closeAddDayModel(flag, isRefresh){
      this.addDayModel = flag;
      if(isRefresh == 'refresh'){
        this.queryEveryDay();
      }
    }
  },

  created(){
    this.queryEveryDay();
  },

  components: {
    Pagination,
    addDay,
    editDay,
    Dialog
  }
}
</script>

<style lang="less" scoped>

</style>
