<!--
 * @Author: CL
 * @Date: 2021-06-25 23:46:52
 * @LastEditTime: 2021-06-25 23:59:59
 * @Description: 修改每日一句模态框
-->

<template>
  <div class="editdaymodel-contain">
    <div class="main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="每日一句" prop="content">
          <el-input type="textarea" clearable class="cl-dialog my-textarea" v-model="ruleForm.content" placeholder="必填，请输入每日一句的内容"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer cl-dialog-footer">
      <el-button class="my-button" @click="closeModel">取消</el-button>
      <el-button class="my-button" type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import { editEveryDay } from '@/api/everyday.js'

export default {
  data(){
    return {
      ruleForm: {
        content: '',    //内容
      },

      rules: {
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
      }
    }
  },

  methods: {
    closeModel(){
      this.close();
    },

    save(){
      this.$refs['ruleForm'].validate(async (valid) => {
        if (!valid) {
          return;
        }
        try{
          const res = await editEveryDay(this.ruleForm.id, this.ruleForm);
          if(res.code == 200){
            this.$showMessage({
              type: 'success',
              message: '修改成功'
            })
            this.close('refresh');
            this.$store.dispatch('everyDay/queryWords');
          }
        }catch(err){
          console.log(err);
        }
      });
    },

    close(data){
      this.$emit('closeEditDayModel', false, data);
      this.$refs['ruleForm'].resetFields();
    },

    firstTo(data){
      this.ruleForm = this.$utils.deepClone(data);
    }
  },

  watch: {
    editInfo: {
      handler(val){
        this.firstTo(val)
      },
      immediate: true
    }
  },

  props: {
    editInfo: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>

</style>
